import Vue from 'vue'
import Router from 'vue-router'

//use Vue Router in Vue JS
Vue.use(Router);

//import pages
import AuthIndex from '@/views/auth/Index'
import TestIndex from '@/views/test/Index'
// import DahsboardIndex from '@/views/dashboard/Index'
// import DahsboardData from '@/views/dashboard/Dashboard'
// import QuestionCategoryIndex from '@/views/questionCategory/Index'

const routes = [
      {
        path: '/',
        name: 'auth',
        component: AuthIndex
      },
      {
        path: '/test',
        name: 'test',
        component: TestIndex
      }
    //   {
    //     path: '/home',
    //     name: 'home',
    //     component: DahsboardIndex,
    //     children: [
    //         {
    //             path: '/dashboard',
    //             name: 'dashboard',
    //             component: DahsboardData, 
    //         },
    //         {
    //             path: '/questionCategory',
    //             name: 'questionCategory',
    //             component: QuestionCategoryIndex,         
    //         },
    //       ],
    //   }
]

const router = new Router ({
    routes:routes,
    linkActiveClass: 'active'
})

export default router