<template>
  <div>
    <b-container fluid="md" class="my-4 mx-auto align-self-center">
      <b-card header-class="text-center">
        <template #header>
          <h4 class="mb-0">Rekrutmen S-Gala.com</h4>
        </template>

        <form @submit.prevent="storeApplicant">
          <div class="form-group">
            <label>Nama:</label>
            <input
              type="text"
              class="form-control"
              v-model="form.name"
              placeholder="Masukkan Nama Anda"
              required="true"
            />
            <div v-if="validation.name">
              <div class="alert alert-danger mt-1" role="alert">
                {{ validation.name[0] }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Usia:</label>
            <input
              type="number"
              class="form-control"
              v-model="form.age"
              placeholder="Masukkan Usia Anda"
              required="true"
            />
            <div v-if="validation.age">
              <div class="alert alert-danger mt-1" role="alert">
                {{ validation.age[0] }}
              </div>
            </div>
          </div>

          <b-form-group
            id="input-group-3"
            label="Jenis Kelamin:"
            label-for="input-3"
          >
            <b-form-select
              id="input-3"
              v-model="form.sex"
              :options="sexs"
              value-field="value"
              text-field="text"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih Jenis Kelamin --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-if="validation.sex">
              <div class="alert alert-danger mt-1" role="alert">
                {{ validation.sex[0] }}
              </div>
            </div>
          </b-form-group>

          <b-form-group
            id="input-group-4"
            label="Tanggal Test:"
            label-for="datepicker-buttons"
          >
            <b-form-datepicker
              v-model="form.test_date"
              id="datepicker-buttons"
              close-button
              readonly
              locale="id"
              required
              aria-required
            ></b-form-datepicker>
          </b-form-group>

          <div class="form-group">
            <label>Email:</label>
            <input
              type="email"
              class="form-control"
              v-model="form.email"
              placeholder="Masukkan Email Anda"
              required="true"
            />
            <div v-if="validation.email">
              <div class="alert alert-danger mt-1" role="alert">
                {{ validation.email[0] }}
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-md btn-primary"
            style="float: right"
            :disabled="isLoading"
          >
            {{ isLoading ? "LOADING..." : "SIMPAN" }}
          </button>
          <button
            type="reset"
            class="btn btn-md btn-danger mr-3"
            style="float: right"
          >
            RESET
          </button>
        </form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        age: null,
        sex: null,
        test_date: new Date().toISOString().slice(0,10),
        email: '',
      },
      validation: [],
      sexs: [
        { text: "Laki-Laki", value: 1 },
        { text: "Perempuan", value: 0 },
      ],
      show: true,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    storeApplicant: function() {
      this.$store.dispatch("storeApplicant", this.form);
      this.form = {
        name: "",
        age: null,
        sex: null,
        test_date: new Date().toISOString().slice(0,10),
        email: "",
      };
      setTimeout(() => {
        this.$router.push({ name: "test" });
      }, 1700);
    },
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.name = "";
      this.form.age = null;
      this.form.sex = null;
      this.form.test_date = "";
      this.form.email = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  background-color: #29b2a5 !important;
}
</style>
