<style>
.jumbotron {
  border-radius: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.lead {
  font-size: 17px;
}
input[type="text"],
[type="number"],
[type="email"] {
  height: 50px;
}
.form-group {
  margin-top: 35px;
}
.fa-thumbs-up {
  color: #0cd80c;
  font-size: 20px;
}
.fa-thumbs-down {
  color: red;
  font-size: 20px;
}
table td {
  padding-top: 5px;
  margin: 0;
}

/*radio button*/
.cont {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a green background */
.cont input:checked ~ .checkmark {
  background-color: #0bb20b;
}

/* Create the indicator (the dot/circle - text when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.cont .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
#message {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 1031;
}
#inner-message {
    margin: 0 auto;
}
</style>

<template>
  <div>
    <!-- Header -->
    <div id="message" class="alert alert-warning alert-dismissible fade show" role="alert">
      <div style="padding: 5px;">
        <div id="inner-message">
        <strong>Perhatian!</strong> Pastikan koneksi internet anda stabil dan tidak melakukan refresh halaman ketika pengerjaan berlangsung. Jika anda melakukan refresh silakan ulangi pengerjaan dari halaman utama rekrutmen. Silakan <i><b>close</b></i> jika sudah mengerti.
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
      </div>
    </div>
    <nav
      style="background-color: #1c7a71;"
      class="navbar navbar-expand-md navbar-dark fixed-top"
    >
      <a
        href="#"
        style="padding: 10px 0px 10px 8%; font-weight: bold; text-transform: uppercase;"
        class="navbar-brand"
        >Online Test Rekrutmen S-Gala.com</a
      >
      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div
          class="navbar-nav ml-auto"
          style="background-color: #E5DDDD; margin: 0;"
          v-for="row in stateApplicant"
          :key="row.email"
        >
          <a
            href="#"
            class="nav-item nav-link"
            style="padding: 20px; color: #000; text-transform: uppercase;"
          >
            {{
              "Selamat mengerjakan, " + row.name
                ? "Selamat mengerjakan, " + row.name
                : "-"
            }}
          </a>
        </div>
      </div>
    </nav>
    <!-- End Header -->
    <form @submit.prevent="postData">
      <!-- Content -->
      <div class="container" style="margin-top: 100px; margin-bottom: 90px;">
        <div class="jumbotron" style="background-color: #FFF;">
          <p style="font-weight: bold;">SOAL TEST</p>
          <hr />
          <p class="lead">
            Tes ini terdiri dari 24 Soal dan 2 jawaban setiap soal. Jawab secara
            jujur dan spontan. Estimasi waktu pengerjaan adalah 20 menit.
          </p>
          <!-- <form> -->
          <div v-for="(row, index) in stateApplicant" :key="row.email">
            <input type="hidden" name="index" :value="index" />
            <input type="hidden" name="name" :value="row.name" />
            <input type="hidden" name="age" :value="row.age" />
            <input type="hidden" name="sex" :value="row.sex" />
            <input type="hidden" name="test_date" :value="row.test_date" />
            <input type="hidden" name="email" :value="row.email" />
          </div>

          <div class="row" v-for="(item, index) in items" :key="index">
            <div class="col-sm-12" style="margin-top: 20px;">
              <div class="card">
                <div class="card-body">
                  <table width="100%" border="0">
                    <tr>
                      <td></td>
                      <td></td>
                      <td style="color: #56DB28;">
                        <i class="fa fa-thumbs-up"></i>
                      </td>
                      <td style="color: #E3451E;">
                        <i class="fa fa-thumbs-down"></i>
                      </td>
                      <td>
                        <h6
                          class="card-title"
                          style="font-weight: bold; color: #9A9797;"
                        >
                          Gambaran Diri
                        </h6>
                      </td>
                      <!-- <p v-for="item in items" :key="item.id">{{ item.question[0].question }}</p> -->
                    </tr>
                    <tr>
                      <td
                        rowspan="5"
                        valign="top"
                        :data-id="item.id"
                        class="num"
                      >
                        <h6
                          class="card-title"
                          style="font-weight: bold; color: #9A9797;"
                        >
                          {{ index + 1 }}
                        </h6>
                      </td>
                    </tr>
                    <tr v-for="(question, index) in item.question" :key="index">
                      <td></td>
                      <td width="9%" valign="top">
                        <label class="cont">
                          <input
                            type="radio"
                            :name="'y' + '[' + item.id + ']'"
                            :id="question.keym + 'm'"
                            :class="item.id + '-y'"
                            :value="index + 1"
                          />
                          <span class="checkmark"></span>
                          <!-- <input type="radio" name="" id="" class="" value="">
										<span class="checkmark"></span> -->
                        </label>
                      </td>
                      <td width="9%" valign="top">
                        <label class="cont">
                          <input
                            type="radio"
                            :name="'n' + '[' + item.id + ']'"
                            :id="question.keyl + 'l'"
                            :class="item.id + '-n'"
                            :value="index + 1"
                          />
                          <span class="checkmark"></span>
                          <!-- <input type="radio" name="" id="" class="" value="">
										<span class="checkmark"></span> -->
                        </label>
                      </td>
                      <td>
                        <p style="color: #9A9797;">{{ question.question }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Content -->

      <!-- Modal Petunjuk -->
      <div
        class="modal fade"
        id="tutorial"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-text="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Tutorial Pengerjaan Soal
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-text="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p align="center">
                <i>
                  "Pastikan koneksi internet anda stabil dan tidak melakukan
                  refresh halaman ketika pengerjaan berlangsung. Jika anda
                  melakukan refresh silakan ulangi pengerjaan dari halaman utama
                  rekrutmen."
                </i>
              </p>
              <p>
                Tes ini terdiri dari 24 Soal dan 2 jawaban setiap soal. Jawab
                secara jujur dan spontan. Estimasi waktu pengerjaan adalah <b>20 menit</b>.
              </p>
              <ul>
                <li>Pelajari semua jawaban pada setiap pilihan</li>
                <li>
                  Pilih satu jawaban yang
                  <strong>paling mendekati diri kamu</strong>
                  (
                  <i style="color:#56DB28" class="fa fa-thumbs-up"></i>
                  )
                </li>
                <li>
                  Pilih satu jawaban yang
                  <strong>paling tidak mendekati diri kamu</strong>
                  (
                  <i style="color:#E3451E" class="fa fa-thumbs-down"></i>
                  )
                </li>
              </ul>
              <p>
                Pada setiap soal harus memiliki jawaban
                <ins>satu</ins>
                <strong> paling mendekati diri kamu</strong>
                dan hanya
                <ins>satu</ins>
                <strong> paling tidak mendekati diri kamu</strong>.
              </p>
              <p>
                Terkadang akan sedikit sulit untuk memutuskan jawaban yang
                terbaik. Ingat, tidak ada jawaban yang benar atau salah dalam
                tes ini.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success"
                data-dismiss="modal"
              >
                Mengerti
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <!-- Footer -->
      <nav
        id="submit"
        style="border: 1px solid #E5DDDD;"
        class="navbar navbar-expand-lg bg-light fixed-bottom"
      >
        <!-- <div class="form-group">
          <label>Test Result:</label>
          <input type="text" class="form-control" v-model="testResultData.question"
                  placeholder="Masukkan Test Data">
      </div> -->

        <input type="hidden" id="D" name="Dm" v-model="testResultData.dm" />
        <input
          type="hidden"
          id="I"
          name="Im"
          v-model.lazy="testResultData.im"
        />
        <input
          type="hidden"
          id="S"
          name="Sm"
          v-model.lazy="testResultData.sm"
        />
        <input
          type="hidden"
          id="C"
          name="Cm"
          v-model.lazy="testResultData.cm"
        />
        <input
          type="hidden"
          id="B"
          name="Bm"
          v-model.lazy="testResultData.bm"
        />

        <ul class="navbar nav ml-auto">
          <input type="hidden" id="K" name="Dl" v-model="testResultData.dl" />
          <input
            type="hidden"
            id="O"
            name="Il"
            v-model.lazy="testResultData.il"
          />
          <input
            type="hidden"
            id="L"
            name="Sl"
            v-model.lazy="testResultData.sl"
          />
          <input
            type="hidden"
            id="E"
            name="Cl"
            v-model.lazy="testResultData.cl"
          />
          <input
            type="hidden"
            id="H"
            name="Bl"
            v-model.lazy="testResultData.bl"
          />
          <li class="nav-item">
            <span id="answered" style="color: #A8A7A7">{{
              countAnswered()
            }}</span
            ><span style="color: #A8A7A7">/</span
            ><span id="total" style="color: #A8A7A7">{{ items.length }}</span>
            <span style="color: #A8A7A7">Soal Terjawab</span>
          </li>
          <li class="nav-item ml-3">
            <b-button
              v-b-tooltip.hover
              title="Petunjuk Pengerjaan"
              variant="info"
              data-toggle="modal"
              data-target="#tutorial"
            >
              <i class="fa fa-question-circle"></i>
            </b-button>
          </li>
          <li class="nav-item ml-3">
            <button
              type="submit"
              id="btn-submit"
              class="btn btn-success"
              disabled
            >
              SUBMIT
            </button>
          </li>
          <!-- </form> -->
        </ul>
      </nav>
    </form>
  </div>
</template>
<script>
import * as questionService from '@/services/question/question-services';
import $ from 'jquery';

export default {
  name: 'exam',

  data() {
    return {
      question: {},
      testResultData: {},
      validation: [],
      items: [],
    };
  },
  watch: {
    items(val) {
      console.log("Total Data Kategori Soal: " + val.length);
    },
    stateApplicant: {
      deep: true,
      immediate: true,
      handler(new_state_applicant, old_state_applicant) {
        if (new_state_applicant !== old_state_applicant) {
          if (new_state_applicant.length > 0) {
            Object.keys(new_state_applicant[0]).forEach((key) => {
              this.testResultData = {
                ...this.testResultData,
                [key]: new_state_applicant[0][key],
              };
            });
          }
        }
      },
    },
  },
  mounted() {
    this.showData();
    this.firstDialog();
    this.answeredQuestion();
  },
  computed: {
    stateApplicant() {
      return this.$store.state.stateApplicant;
    },
  },
  methods: {
    answeredQuestion: function() {
      var vm = this;

      $(document).on("change", "input[type=radio]", function() {
        var className = $(this).attr("class");
        var currentNumber = className.split("-")[0];
        var currentCode = className.split("-")[1];
        var oppositeCode = currentCode == "y" ? "n" : "y";
        var currentValue = $(this).val();
        var oppositeValue = $(
          "." + currentNumber + "-" + oppositeCode + ":checked"
        ).val();

        // Detect if one question has same answer
        if (currentValue == oppositeValue) {
          $("." + currentNumber + "-" + oppositeCode + ":checked").prop(
            "checked",
            false
          );
          oppositeValue = $(
            "." + currentNumber + "-" + oppositeCode + ":checked"
          ).val();
        }

        var Dm = $("#Dm:checked").length;
        var Im = $("#Im:checked").length;
        var Sm = $("#Sm:checked").length;
        var Cm = $("#Cm:checked").length;
        var Bm = $("#Bm:checked").length;

        document.getElementById("D").value = Dm;
        if (vm.testResultData["dm"] !== undefined) {
          vm.testResultData["dm"] = Dm;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            dm: Dm,
          };
        }

        document.getElementById("I").value = Im;
        if (vm.testResultData["im"] !== undefined) {
          vm.testResultData["im"] = Im;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            im: Im,
          };
        }

        document.getElementById("S").value = Sm;
        if (vm.testResultData["sm"] !== undefined) {
          vm.testResultData["sm"] = Sm;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            sm: Sm,
          };
        }

        document.getElementById("C").value = Cm;
        if (vm.testResultData["cm"] !== undefined) {
          vm.testResultData["cm"] = Cm;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            cm: Cm,
          };
        }

        document.getElementById("B").value = Bm;
        if (vm.testResultData["bm"] !== undefined) {
          vm.testResultData["bm"] = Bm;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            bm: Bm,
          };
        }

        var Dl = $("#Dl:checked").length;
        var Il = $("#Il:checked").length;
        var Sl = $("#Sl:checked").length;
        var Cl = $("#Cl:checked").length;
        var Bl = $("#Bl:checked").length;

        document.getElementById("K").value = Dl;
        if (vm.testResultData["dl"] !== undefined) {
          vm.testResultData["dl"] = Dl;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            dl: Dl,
          };
        }

        document.getElementById("O").value = Il;
        if (vm.testResultData["il"] !== undefined) {
          vm.testResultData["il"] = Il;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            il: Il,
          };
        }

        document.getElementById("L").value = Sl;
        if (vm.testResultData["sl"] !== undefined) {
          vm.testResultData["sl"] = Sl;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            sl: Sl,
          };
        }

        document.getElementById("E").value = Cl;
        if (vm.testResultData["cl"] !== undefined) {
          vm.testResultData["cl"] = Cl;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            cl: Cl,
          };
        }

        document.getElementById("H").value = Bl;
        if (vm.testResultData["bl"] !== undefined) {
          vm.testResultData["bl"] = Bl;
        } else {
          vm.testResultData = {
            ...vm.testResultData,
            bl: Bl,
          };
        }

        // Count answered question
        vm.countAnswered();

        // Enable submit button
        var totalQuestion = document.getElementById("total").innerHTML;
        console.log("total question: " + totalQuestion);
        console.log("total count: " + vm.countAnswered());
        vm.countAnswered() >= totalQuestion
          ? $("#btn-submit").removeAttr("disabled")
          : $("#btn-submit").removeAttr("disabled");
        // ? $("#btn-submit").removeAttr("disabled")
        // : $("#btn-submit").attr("disabled", "disabled");
      });
    },

    answerValidation: function() {
      this.$swal({
        title: "Data Gagal Disimpan",
        text: "Pertanyaan belum terisi semua, pastikan anda sudah mengisi semua pertanyaan",
        icon: "warning",
      });
    },

    testResultStore(testResultData) {
      this.countAnswered();
      var totalQuestion = document.getElementById("total").innerHTML;
      if (this.countAnswered() < totalQuestion) {
        this.answerValidation();
      } else {
        this.$swal({
          title: "Informasi",
          text: "Apakah anda yakin telah selesai?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Tidak",
          confirmButtonText: "Ya, Saya Yakin!",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
              questionService.create(testResultData)
              .then((response) => {
                this.alertSuccess(response.data.message);

                this.$router.push({
                  name: "auth",
                });
                this.$store.dispatch("resetApplicant");
                // location.reload();
                console.log(response.data);
              })
              .catch((error) => {
                this.alert(error);
                this.validation = error.response.data;
              });
          }
        });
      }
    },

    postData() {
        this.testResultStore(this.testResultData);
    },

    countAnswered: function() {
      var total = 0;
      // var vm = this
      $(".num").each(function(key, elem) {
        var id = $(elem).data("id");
        var mValue = $("." + id + "-y:checked").val();
        var lValue = $("." + id + "-n:checked").val();
        mValue != undefined && lValue != undefined ? total++ : "";
        console.log(mValue + " - " + lValue);
      });
      $("#answered").text(total);
      return total;
    },

    firstDialog: async function() {
      $("#tutorial").modal("toggle");
    },

    showData: async function() {
      try {
        const response = await questionService.showData();
        console.log(response);
        this.items.unshift(response.data);
        this.items = response.data.data;
        // console.log(this.items);
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message:
            "Terjadi kesalahan teknis, silahkan refresh atau hubungi tim IT.",
          time: 2000,
        });
      }
    },

    alertSuccess(message) {
      this.$swal("Test Anda Sudah Selesai!", message, "success");
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>
