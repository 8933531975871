import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: false,
        stateApplicant: [],
        //local
        // apiURL: 'http://localhost:8081/api',
        // serverPath: 'http://localhost:8081',

        //server
        apiURL: 'https://rekrutmen-services.s-gala.com/api',
        serverPath: 'https://rekrutmen-services.s-gala.com'
    },
    mutations: {
        APPLICANT_CONFIRMATION: (state, applicant) => {
            state.stateApplicant.push(applicant)
        },
        APPLICANT_RESET: (state) => {
            state.stateApplicant = []
        }
    },
    actions: {
        storeApplicant({ commit, state }, applicant) {
            state.isLoading = true
            setTimeout(() => {
                commit('APPLICANT_CONFIRMATION', applicant)
                state.isLoading = false
            }, 1500)
        },
        resetApplicant({ commit, state }) {
            state.isLoading = true
            setTimeout(() => {
                commit('APPLICANT_RESET')
                state.isLoading = false
            }, 1500)
        }
    },
});